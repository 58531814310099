import { StatusType, datadogLogs } from '@datadog/browser-logs';

interface LoggingData {
  message: string;
  error?: Error;
  status?: StatusType;
  messageContext?: object;
  productArea?:
    | 'auth'
    | 'biopage'
    | 'gema'
    | 'misc'
    | 'royalties'
    | 'release'
    | 'subscription'
    | 'track';
  // alertLevel?: 'low' | 'medium' | 'high';
}

class Logging {
  error({ message, error, messageContext, productArea }: LoggingData) {
    datadogLogs.logger.error(
      message,
      productArea ? { ...messageContext, productArea } : messageContext,
      error
    );
  }
  info({ message, error, messageContext, productArea }: LoggingData) {
    datadogLogs.logger.info(
      message,
      productArea ? { ...messageContext, productArea } : messageContext,
      error
    );
  }
  log({ message, error, status, messageContext, productArea }: LoggingData) {
    datadogLogs.logger.log(
      message,
      productArea ? { ...messageContext, productArea } : messageContext,
      status,
      error
    );
  }
}

export const logging = new Logging();
